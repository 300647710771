import application from 'stimulus_application';
import { definitionsFromContext } from 'stimulus/webpack-helpers';
import './content_happenings.css';
import 'shared/assets/css/vendor/onetrust.css';

const context = require.context('./', true, /_controller\.js$/);
const addContext = require.context('shared/assets/javascript/', true, /_controller\.js$/);
application.load(definitionsFromContext(context));
application.load(definitionsFromContext(addContext));

// FIXME: background image
