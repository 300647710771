import './one_trust_ready.js'
const ONETRUST_SOCIAL_GROUP = 8;

export default class Social {
  load() {
    this.loadScripts();
  }

  loadScripts() {
    this.fbRoot = document.getElementById('fb-root');
    if (this.fbRoot == null) return;
    if (typeof FB !== 'undefined' && this.fbRoot.children.length === 0) {
      this.fbRoot.innerHTML = this.fbRootSaved;
    }
    if (typeof FB !== 'undefined') return FB.XFBML.parse();

    OneTrustReady.register(() => {
      this.loadScriptsOneTrust();
    });
  }

  loadScriptsOneTrust() {
    OneTrust.InsertScript(
      'https://connect.facebook.net/en_US/sdk.js',
      'head',
      () => this.getFB(),
      null,
      ONETRUST_SOCIAL_GROUP
    );

    OneTrust.InsertScript('https://platform.twitter.com/widgets.js', 'head', null, null, ONETRUST_SOCIAL_GROUP);
  }

  getFB() {
    FB.init({ appId: 1264050613694938, xfbml: true, version: 'v2.10' });
    this.fbRootSaved = this.fbRoot.innerHTML;
  }
}
