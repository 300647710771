import { Controller } from 'stimulus';
import Social from 'shared/assets/javascript/social';

const ONETRUST_FUNCTIONAL_GROUP = 3;

export default class extends Controller {
  state = {
    loaded: false,
    queue: [],
  };

  connect() {
    console.log('Spotify controller connected');
    const social = new Social();
    social.load();
    this.load();
  }

  load() {
    console.log(OneTrustReady);
    OneTrustReady.register(() => {
      const module = this.element;
      const src = module.dataset.src;
      OneTrust.InsertHtml(
        `<iframe
          style="border-radius:12px"
          src="${src}"
          height="152"
          frameBorder="0"
          allowfullscreen=""
          allow="autoplay; clipboard-write; encrypted-media; fullscreen;  picture-in-picture"
          loading="lazy"
        ></iframe>`,
        'spotify-module',
        () => module.css({ display: 'flex' }),
        null,
        ONETRUST_FUNCTIONAL_GROUP
      );
    });
  }
}
