import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['bg']

  connect() {
    document.addEventListener("DOMContentLoaded", this.lazyLoadBG.bind(this))
  }

  lazyLoadBG() {
    if ("IntersectionObserver" in window) {
      let lazyBackgroundObserver = new IntersectionObserver(function(entries, observer) {
        entries.forEach(function(entry) {
          if (entry.isIntersecting) {
            entry.target.style.backgroundImage = `url('${entry.target.dataset.image}')`
            lazyBackgroundObserver.unobserve(entry.target)
          }
        });
      });
      lazyBackgroundObserver.observe(this.bgTarget)
    }
  }
}
